/* 在线链接服务仅供平台体验和调试使用，平台不承诺服务的稳定性，企业客户需下载字体包自行发布使用并做好备份。 */
@font-face {
    font-family: 'iconfont';  /* Project id 2736943 */
    src: url('//at.alicdn.com/t/c/font_2736943_7rk6obf1y6o.woff2?t=1713249835344') format('woff2'),
         url('//at.alicdn.com/t/c/font_2736943_7rk6obf1y6o.woff?t=1713249835344') format('woff'),
         url('//at.alicdn.com/t/c/font_2736943_7rk6obf1y6o.ttf?t=1713249835344') format('truetype');
}

.iconfont {
    font-family: "iconfont" !important;
    font-size: 16px;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -webkit-text-stroke-width: 0.2px;
    -moz-osx-font-smoothing: grayscale;
}
