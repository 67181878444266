
.student-container {
    height: 100%;
    display: flex;
    background: #eeeff3;
}

.student-left {
    min-width: 240px;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-right: 20px;

    .student-avatar-logo {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 16px 0 0 10px;

        i {
            font-size: 30px;
            color: #2821fc;
        }

        .student-avatar-title {
            font-size: 20px;
            font-weight: 400;
            display: inline-block;
            color: #332d42;
            margin-left: 10px;
        }
    }

    .student-avatar-user {
        margin-top: 36px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .student-avatar {
            width: 84px;
            height: 84px;
            border-radius: 50%;
            overflow: hidden;

            img {
                max-height: 100%;
                max-width: 100%;
            }
        }

        .student-icon {
            margin-top: 18px;
            font-size: 18px;
            font-weight: 500;
            color: #332d42;
            max-width: 200px;
            display: flex;
            align-items: center;

            i {
                font-size: 20px;
                margin-left: 9px;
                cursor: pointer;
            }
        }
    }
}

.menu-box {
    margin-top: 36px;
    flex: 1;
    height: 1%;

    .levelOne-box {
        position: relative;
        width: 100%;
        height: 100%;
        padding-top: 20px;
        display: flex;
        flex-direction: column;

        ::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;

            .el-scrollbar__view {
                height: 100%;
            }
        }

        .levelOne {
            height: 60px;
            padding: 0 10px 0 30px;
            display: flex;
            align-items: center;
            color: #666;
            line-height: 1;
            transition: all 0.3s;

            .iconfont {
                font-size: 18px;
            }

            .one-text {
                font-size: 18px;
                margin-left: 20px;
                line-height: 18px;
            }

            &:hover {
                color: #2821fc;
            }

            &.current {
                color: #fff;
                background: #2821fc;
                border-radius: 0px 20px 20px 0px;

                .icon {
                    background: #2821fc;
                }
            }
        }
    }
}

.student-right {
    height: 100%;
    flex: 1;
    width: 1%;
    display: flex;
    flex-direction: column;

    .levelTwo-wrapper {
        margin: 20px 0 0 39px;
        display: flex;

        .two-text {
            width: 154px;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 16px;
            font-weight: 500;
            color: #5b5b6d;
            position: relative;

            .text-main {
                z-index: 10;
            }
        }

        .current {
            .two-text {
                background: #ffffff;
                color: #4b45ff;
                border-radius: 20px 20px 0 0;

                .wider-box {
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 194px;
                    height: 20px;
                    background-color: #fff;

                    &::before {
                        content: "";
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        width: 20px;
                        height: 20px;
                        background-color: #EEEFF3;
                        z-index: 999;
                        border-radius: 0 0 20px 0;
                    }

                    &::after {
                        content: "";
                        position: absolute;
                        right: 0;
                        bottom: 0;
                        width: 20px;
                        height: 20px;
                        background-color: #EEEFF3;
                        z-index: 999;
                        border-radius: 0 0 0 20px;
                    }
                }
            }
        }
    }

    .content {
        background: #ffffff;
        flex: 1;
        height: 1%;
        border-radius: 20px 0px 0px 20px;
    }
}
